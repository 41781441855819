export const createFAQ = (content) => {
    const contentArr = content && content.match(/###?#?.+\?\n+[a-zA-Z0-9]+.+/g);
    let faqs = [];
    if (contentArr) {
        contentArr.forEach((item) => {
            const [question, answer] = getQuestionAnswer(item);
            faqs.push({
                '@type': 'Question',
                name: question,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: answer
                }
            });
        });
    }

    return faqs;
};

const getQuestionAnswer = (content) => {
    const qa = content.split(/\?/); // Include the question mark in the split
    const question = qa[0] + '?';
    const answer = qa[1];
    const pureQuestion = question.replace(/#/g, '');
    const pureAnswer = answer.replace(/\n/g, '');

    return [pureQuestion, pureAnswer];
};

export function setupArticleSchema(data, slug) {
    let schemaArticle;

    if (data.schemaType === 'FAQ') {
        schemaArticle = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            datePublished: data.date,
            dateModified: data.updatedAt,
            mainEntity: []
        };
        data.content.map((data) => {
            if (data.blogContent && data.blogContent.blogContent) {
                const faqs = createFAQ(data.blogContent.blogContent);

                return schemaArticle.mainEntity.push(...faqs);
            }
            return null;
        });
    } else {
        schemaArticle = {
            '@context': 'http://schema.org',
            '@type': 'Article',
            headline: data.articleTitle,
            publisher: {
                '@type': 'Organization',
                name: 'iwoca',
                url: 'https://www.iwoca.co.uk/',
                logo: {
                    '@type': 'ImageObject',
                    url:
                        'https://images.ctfassets.net/835lsoe3tdrh/60hozXHqoyXt2BpOMzmF2L/60004d18bd71bc07058f65091d2d69cd/logo-x2-doors.png'
                }
            },
            mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': slug
            },
            author: {
                '@type': 'Person',
                name: data.author && data.author.authorName
            },
            datePublished: data.date,
            dateModified: data.updatedAt,
            image:
                data &&
                data.articleHeaderImage &&
                data.articleHeaderImage.file &&
                data.articleHeaderImage.file.url &&
                data.articleHeaderImage.file.url.replace(/\/\//, '')
        };
    }

    return [schemaArticle];
}

export const setupVideoSchema = (videos, allContent) => {
    if (allContent) {
        const videoSchema = allContent.reduce((contents, content) => {
            if (
                content.__typename === 'ContentfulBlogMediaAndCaption' &&
                content.youtubeVideoId &&
                content.youtubeVideoId !== ''
            ) {
                const video = videos.find(
                    (video) => video.id === content.youtubeVideoId
                );

                if (video) {
                    contents.push({
                        '@context': 'https://schema.org',
                        '@type': 'VideoObject',
                        name: video.snippet.title,
                        description: video.snippet.description,
                        thumbnailUrl: [video.snippet.thumbnails.default.url],
                        uploadDate: video.snippet.publishedAt,
                        duration: video.contentDetails.duration,
                        contentUrl: `https://www.youtube.com/watch?v=${video.id}`,
                        embedUrl: `https://www.youtube.com/watch?v=${video.id}`,
                        interactionStatistic: {
                            '@type': 'InteractionCounter',
                            interactionType: {
                                '@type': 'http://schema.org/WatchAction'
                            },
                            userInteractionCount: video.statistics.viewCount
                        }
                    });
                }
            }
            return contents;
        }, []);

        return videoSchema;
    }
    return [];
};
