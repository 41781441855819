import React from 'react';
import styles from '../ContentfulBlogContentBlock.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { StockUpSmallCategory } from './StockUpSmallCategory';

export const StockUpSmall = () => {
    const stockUpSmallData = useStaticQuery(graphql`
        query stockUpSmallQuery {
            susActivities: allContentfulStockUpSmallLocation(
                filter: { category: { eq: "Activities" } }
            ) {
                edges {
                    node {
                        ...StockUpSmallCategory
                    }
                }
            }
            susAlcohol: allContentfulStockUpSmallLocation(
                filter: { category: { eq: "Alcohol" } }
            ) {
                edges {
                    node {
                        ...StockUpSmallCategory
                    }
                }
            }
            susRetail: allContentfulStockUpSmallLocation(
                filter: { category: { eq: "Retail" } }
            ) {
                edges {
                    node {
                        ...StockUpSmallCategory
                    }
                }
            }
            susGroceries: allContentfulStockUpSmallLocation(
                filter: { category: { eq: "Groceries and essentials" } }
            ) {
                edges {
                    node {
                        ...StockUpSmallCategory
                    }
                }
            }
            susMeals: allContentfulStockUpSmallLocation(
                filter: { category: { eq: "Meal delivery and take away" } }
            ) {
                edges {
                    node {
                        ...StockUpSmallCategory
                    }
                }
            }
            susSnack: allContentfulStockUpSmallLocation(
                filter: { category: { eq: "Snack and treats" } }
            ) {
                edges {
                    node {
                        ...StockUpSmallCategory
                    }
                }
            }
            susHealth: allContentfulStockUpSmallLocation(
                filter: { category: { eq: "Health and fitness" } }
            ) {
                edges {
                    node {
                        ...StockUpSmallCategory
                    }
                }
            }
            susEducation: allContentfulStockUpSmallLocation(
                filter: { category: { eq: "Education" } }
            ) {
                edges {
                    node {
                        ...StockUpSmallCategory
                    }
                }
            }
        }

        fragment StockUpSmallCategory on ContentfulStockUpSmallLocation {
            id
            companyName
            website
            category
            doTheyDeliver
            deliveryAreas
            emailAddress
            howDoYouOrder
            phoneNumber
            specialOffers {
                specialOffers
            }
            whatDoTheySell {
                whatDoTheySell
            }
        }
    `);
    return (
        <div className={styles.ArticleText}>
            {
                <>
                    <StockUpSmallCategory
                        title="Groceries"
                        data={stockUpSmallData.susGroceries.edges}
                    />
                    <StockUpSmallCategory
                        title="Meal delivery and take away"
                        data={stockUpSmallData.susMeals.edges}
                    />
                    <StockUpSmallCategory
                        title="Alcoholic drinks"
                        data={stockUpSmallData.susAlcohol.edges}
                    />
                    <StockUpSmallCategory
                        title="Snack and treats"
                        data={stockUpSmallData.susSnack.edges}
                    />
                    <StockUpSmallCategory
                        title="Retail"
                        data={stockUpSmallData.susRetail.edges}
                    />
                    <StockUpSmallCategory
                        title="Health and fitness"
                        data={stockUpSmallData.susHealth.edges}
                    />
                    <StockUpSmallCategory
                        title="Education"
                        data={stockUpSmallData.susEducation.edges}
                    />
                    <StockUpSmallCategory
                        title="Activities"
                        data={stockUpSmallData.susActivities.edges}
                    />
                </>
            }
        </div>
    );
};
