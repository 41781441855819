import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ArticlePageContextProvider } from '../ArticlePageContext';
import OpinionWidget from './OpinionWidget/OpinionWidget';
import CTABox from '../../../../components/CTABox/CTABox';
import Author from './Author/Author';
import MediaAndCaption from './MediaAndCaption/MediaAndCaption';
import Navigator from './Navigator/Navigator';
import RecentArticles from './RecentArticles/RecentArticles';
import styles from './ContentBlock.module.scss';
import GatsbyImg from 'gatsby-image';
import Spacer from './Spacer/Spacer';
import ContentfulBlogContentBlock from './ContentfulBlogContentBlock/ContentfulBlogContentBlock.jsx';
import Social from './Social/Social';
import WantToKnowMore from '../../common/WantToKnowMore/WantToKnowMore';
import CTABoxTransactionalPage from '../../../../components/CTABoxes/CTABoxTransactionalPage/CTABoxTransactionalPage';
import HowLoansWork from './HowLoansWork/HowLoansWork';

export function renderContent(allContent) {
    if (!allContent) {
        console.error('Article Page: No content provided');
        return [];
    }

    const result = allContent.map((content) => {
        let component;

        switch (content.__typename) {
            case 'ContentfulBlogContentBlock':
                component = (
                    <ContentfulBlogContentBlock
                        blogContent={content.blogContent.blogContent}
                        key={content.id}
                    />
                );
                break;
            case 'ContentfulBlogCallToActionBox':
                component = <CTABox content={content} key={content.id} />;
                break;
            case 'ContentfulBlogCovidInformationBlock':
                component = (
                    <div className={styles.covidInfo}>
                        <div className={styles.covidInfoHeader}>
                            <h3>Coronavirus information</h3>
                        </div>
                        <div className={styles.covidInfoBody}>
                            <ReactMarkdown
                                source={
                                    content.covidInformationBody
                                        .covidInformationBody
                                }
                                className={styles.covidInfoContent}
                            />
                        </div>
                    </div>
                );
                break;
            case 'ContentfulBlogMediaAndCaption':
                component = <MediaAndCaption data={content} key={content.id} />;
                break;
            default:
                component = null;
                break;
        }
        return component;
    });
    return result;
}

const ContentBlock = ({
    content,
    author,
    links,
    categoryTag,
    articleName,
    headerImage,
    fullSlug,
    updatedAt,
    isATransactionalPage,
    faqSection,
    howLoansWork
}) => {
    const isCovidPage = categoryTag === 'Covid-19';

    return (
        <ArticlePageContextProvider>
            <div className={styles.contentWrapper}>
                <Spacer />
                <div className={styles.headerImageWrapper}>
                    <GatsbyImg
                        className={styles.HeaderImage}
                        fluid={headerImage.fluid}
                    />
                </div>
                <div className={styles.ContentBlock}>
                    {renderContent(content)}
                    {isATransactionalPage && (
                        <WantToKnowMore
                            faq={faqSection.faqs}
                            className={styles.faqs}
                            isTransactionalPage={true}
                        />
                    )}
                    <RecentArticles
                        articleName={articleName}
                        categoryTag={categoryTag}
                        isCovidPage={isCovidPage}
                    />
                    <Navigator
                        isCovidPage={isCovidPage}
                        links={links}
                        fullSlug={fullSlug}
                    />
                    {isATransactionalPage && (
                        <>
                            <HowLoansWork data={howLoansWork} />
                            <CTABoxTransactionalPage />
                        </>
                    )}
                    <Social
                        fullSlug={fullSlug}
                        isTransactionalPage={isATransactionalPage}
                    />
                    {author && <Author author={author} date={updatedAt} />}
                    <OpinionWidget
                        className={styles.OpinionWidget}
                        buttonContainerClass={styles.ButtonContainer}
                        successText="Thank you for your feedback!"
                    />
                </div>
            </div>
        </ArticlePageContextProvider>
    );
};

export default ContentBlock;
