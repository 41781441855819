import React from 'react';

const RightQuote = ({ className }) => {
    return (
        <svg
            className={className}
            width="217"
            height="197"
            viewBox="0 0 217 197"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M125.268 98.3417C125.268 124.328 118.026 148.184 103.542 169.91C89.0581 191.636 69.4621 207.824 44.7541 218.474C43.0501 219.326 40.7071 219.752 37.7251 219.752C30.4831 219.752 25.5841 216.131 23.0281 208.889L13.4431 184.607C12.1651 181.625 11.5261 179.069 11.5261 176.939C11.5261 171.401 15.5731 166.076 23.6671 160.964C36.4471 152.444 45.6061 144.137 51.1441 136.043C57.1081 127.523 60.0901 118.364 60.0901 108.566V100.259C41.7721 100.259 27.2881 95.9987 16.6381 87.4787C5.98809 78.5327 0.663089 66.6047 0.663089 51.6947C0.663089 36.3587 5.77509 24.0047 15.9991 14.6327C26.6491 5.26072 40.2811 0.57472 56.8951 0.57472C78.1951 0.57472 94.8091 8.88173 106.737 25.4957C119.091 42.1097 125.268 64.9007 125.268 93.8687V98.3417ZM272.877 98.3417C272.877 124.328 265.635 148.184 251.151 169.91C236.667 191.636 217.071 207.824 192.363 218.474C190.659 219.326 188.316 219.752 185.334 219.752C178.092 219.752 173.193 216.131 170.637 208.889L161.052 184.607C159.774 181.625 159.135 179.069 159.135 176.939C159.135 171.401 163.182 166.076 171.276 160.964C184.056 152.444 193.215 144.137 198.753 136.043C204.717 127.523 207.699 118.364 207.699 108.566V100.259C189.381 100.259 174.897 95.9987 164.247 87.4787C153.597 78.5327 148.272 66.6047 148.272 51.6947C148.272 36.3587 153.384 24.0047 163.608 14.6327C174.258 5.26072 187.89 0.57472 204.504 0.57472C225.804 0.57472 242.418 8.88173 254.346 25.4957C266.7 42.1097 272.877 64.9007 272.877 93.8687V98.3417Z"
                fill="#234152"
            />
        </svg>
    );
};

export default RightQuote;
