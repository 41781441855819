import React from 'react';

import styles from './HowLoansWork.module.scss';

const HowLoansWork = ({ data }) => {
    const renderPoints = () => {
        return data.howLoanWorksPoint.map((point, i) => (
            <div className={styles.container}>
                <div className={styles.number}>{i + 1}</div>
                <div className={styles.how}>
                    <p className={styles.title}>{point.title}</p>
                    <p className={styles.content}>{point.content.content}</p>
                </div>
            </div>
        ));
    };

    return (
        <section className={styles.HowLoansWork}>
            <h2>{data.title}</h2>
            <hr />
            {renderPoints()}
        </section>
    );
};

export default HowLoansWork;
