import React from 'react';

const BookSVG = () => {
    return (
        <svg
            width="202"
            height="267"
            viewBox="0 0 202 267"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="2.25259" width="200.49" height="263.565" fill="black" />
            <path
                d="M200.594 265.818C200.594 265.818 54.0376 265.818 5.8329 265.818C2.25461 265.818 3.12362 265.527 2.2546 265.333L2.25444 0C2.25444 0 2.10448 0 5.06935 0C53.8875 0 200.49 6.45172e-05 200.49 6.45172e-05C201.617 0 201.617 1.12635 201.617 1.12635V264.849C201.617 265.382 201.157 265.818 200.594 265.818Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M200.49 266.944V0C201.11 0 201.617 0.436025 201.617 0.968944V265.975C201.617 266.944 201.617 266.944 200.49 266.944Z"
                fill="url(#paint1_linear)"
            />
            <g style={{ mixBlendMode: 'multiply' }}>
                <rect
                    x="2.25317"
                    width="198.237"
                    height="265.818"
                    fill="url(#paint2_radial)"
                />
            </g>
            <path
                d="M0.000488281 2.42761L2.25318 4.57764e-05V265.818L0.000488281 264.604V2.42761Z"
                fill="#3A83DC"
            />
            <g style={{ mixBlendMode: 'color' }}>
                <rect
                    x="2.25317"
                    width="198.237"
                    height="265.818"
                    fill="#3A83DC"
                />
            </g>
            <path
                d="M0.253174 266.381V265.818C4.44492 265.818 4.44492 265.818 5.87624 265.818C54.3369 265.818 199.616 265.818 199.616 265.818C199.616 266.944 199.616 266.944 198.49 266.944C198.49 266.944 51.9854 266.944 3.83149 266.944C0.40653 266.944 0.253174 266.381 0.253174 266.381Z"
                fill="url(#paint3_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="2.25452"
                    y1="132.346"
                    x2="201.618"
                    y2="132.346"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EDEDED" />
                    <stop offset="0.00865817" stop-color="#F5F5F5" />
                    <stop offset="0.0208333" stop-color="#FAFAFA" />
                    <stop offset="0.0294872" stop-color="#F0F0F0" />
                    <stop offset="0.0378205" stop-color="#CCCCCC" />
                    <stop offset="0.05" stop-color="white" />
                    <stop offset="0.0621795" stop-color="#E3E3E3" />
                    <stop offset="1" stop-color="#EBEBEB" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="200.492"
                    y1="133.472"
                    x2="201.619"
                    y2="133.472"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.1" stop-color="white" />
                    <stop offset="1" stop-color="#CCCCCC" />
                </linearGradient>
                <radialGradient
                    id="paint2_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(34.5302 38.0086) rotate(73.6478) scale(237.413 175.754)">
                    <stop stop-color="#F87E7E" />
                    <stop offset="1" stop-color="#D44C4C" />
                </radialGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="99.9366"
                    y1="265.818"
                    x2="99.9366"
                    y2="266.944"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.1" stop-color="white" />
                    <stop offset="1" stop-color="#CCCCCC" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default BookSVG;
