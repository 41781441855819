import React from 'react';

const Calendar = () => (
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.70041 8.47559H6.62988V43.7539L9.70041 45.3219H40.4057L41.9409 44.538L43.4762 43.7539V8.47559H40.4057"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.62988 16.1523H43.4762"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.7002 22.293H15.8412"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.9824 22.293H28.1235"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.2637 22.293H40.4047"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.7002 37.6455H15.8412"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.9824 37.6455H28.1235"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.2637 37.6455H40.4047"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.7002 29.9688H15.8412"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.1235 31.5041L21.9824 28.4336"
            stroke="#FB534A"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.1235 28.4336L21.9824 31.5041"
            stroke="#FB534A"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.4047 31.5041L34.2637 28.4336"
            stroke="#FB534A"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.4047 28.4336L34.2637 31.5041"
            stroke="#FB534A"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.9824 10.0106L25.0529 11.5458L28.1235 10.0106V5.40479H21.9824V10.0106Z"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.2637 10.0106L37.3342 11.5458L40.4047 10.0106V5.40479H34.2637V10.0106Z"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.7002 10.0106L12.7707 11.5458L15.8412 10.0106V5.40479H9.7002V10.0106Z"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.8398 9.24316H21.9809"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.123 9.24316H34.2641"
            stroke="#BFD4E0"
            strokeWidth="1.91908"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Calendar;
