import React from 'react';
import SocialMediaBar from './SocialMediaBar/SocialMediaBar';
import Chevron from '../../../../../SVGComponents/Chevron';
import styles from './Navigator.module.scss';
import OpinionWidget from '../OpinionWidget/OpinionWidget';

const Navigator = ({ links, fullSlug }) => {
    return (
        <div className={styles.Container}>
            <div className={styles.NavigatorWrapper}>
                <div className={styles.Navigator}>
                    {links && (
                        <ul>
                            {links.map((link, i) => {
                                return (
                                    <li key={i} className={styles.Link}>
                                        <a href={link.link}>
                                            {link.displayName}
                                        </a>
                                        <Chevron className={styles.Chevron} />
                                    </li>
                                );
                            })}
                            <hr />
                        </ul>
                    )}
                    <SocialMediaBar fullSlug={fullSlug} />
                    <OpinionWidget
                        successText="Thank you!"
                        displayEmoji={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default Navigator;
