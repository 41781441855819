import React from 'react';
import Button from 'aphrodite-shared/ui/Button/Button';
import BookSVG from './Book/Book';

import styles from './IWGuide.module.scss';

const IwGuide = ({ header, description, bookHeading, bookSubheading }) => {
    return (
        <section className={styles.Guide}>
            <div className={styles.leftPane}>
                <h3>{header}</h3>
                <p>{description}</p>
                <Button
                    colour="primary"
                    href="https://app.monstercampaigns.com/c/yrs9wtppymphweuij62x/"
                    className={styles.applyButton}
                    dataGaId="insights__send_me_the_guide">
                    Send me the guide
                </Button>
            </div>
            <div className={styles.rightPane}>
                <BookSVG heading={bookHeading} subHeading={bookSubheading} />
            </div>
        </section>
    );
};

export default IwGuide;
