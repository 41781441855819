import React from 'react';
import styles from './CreditHealthCheckQuizIframe.module.scss';

class CreditHealthCheckQuizIframe extends React.Component {
    constructor() {
        super();
        this.state = {
            iFrameHeight: '0px'
        };

        this.handleDocHeightMsg = this.handleDocHeightMsg.bind(this);
    }

    handleDocHeightMsg(event) {
        if (event.origin === 'https://iwoca.netlify.app') {
            var data = JSON.parse(event.data);
            if (data['innerHeight']) {
                this.setState({ iFrameHeight: data['innerHeight'] + 'px' });
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.handleDocHeightMsg, false);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleDocHeightMsg);
    }

    render() {
        return (
            <div className={styles.quiz}>
                <iframe
                    title="Credit health check"
                    style={{
                        width: '100%',
                        height: this.state.iFrameHeight,
                        overflow: 'visible'
                    }}
                    ref="iframe"
                    src="https://iwoca.netlify.app"
                    width="100%"
                    height={this.state.iFrameHeight}
                    scrolling="no"
                    frameBorder="0"
                />
            </div>
        );
    }
}

export default CreditHealthCheckQuizIframe;
