import React from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import ShortBlockQuote from '../Quotes/ShortBlockQuote/ShortBlockQuote';
import LongInlineQuote from '../Quotes/LongInlineQuote/LongInlineQuote';
import MathJax from 'react-mathjax2';
import CreditHealthCheckQuizIframe from '../../../../../components/ContentHub/Article/ArticleContent/CreditHealthCheckQuizIframe/CreditHealthCheckQuizIframe';
import styles from './ContentfulBlogContentBlock.module.scss';
import * as RemarkMathPlugin from 'remark-math';
import { StockUpSmall } from './StockUpSmallCategory/StockUpSmall';

export const MarkdownRender = (props) => {
    const newProps = {
        ...props,
        plugins: [RemarkMathPlugin],
        renderers: {
            ...props.renderers,
            math: (props) => <MathJax.Node>{props.value}</MathJax.Node>,
            inlineMath: (props) => (
                <MathJax.Node inline>{props.value}</MathJax.Node>
            )
        }
    };
    return (
        <MathJax.Context input="tex">
            <ReactMarkdown {...newProps} />
        </MathJax.Context>
    );
};

const defaultRenderers = {
    code: ({ language, value }) => {
        if (language === 'smallQuote') {
            return <ShortBlockQuote content={value} />;
        }

        if (language === 'bigQuote') {
            return <LongInlineQuote content={value} />;
        }

        if (language === 'noFollow') {
            return (
                <ReactMarkdown
                    source={value}
                    className={styles.noFollow}
                    renderers={{
                        link: ({ href, children }) => {
                            return (
                                <a href={href} rel="nofollow">
                                    {children[0]}
                                </a>
                            );
                        }
                    }}
                />
            );
        }

        return null;
    }
};

const ContentfulBlogContentBlock = ({ blogContent }) => {
    function renderContent() {
        if (!blogContent) {
            return null;
        }

        if (blogContent.includes('{{quiz}}')) {
            let splitContent = blogContent.split('{{quiz}}');
            return (
                <React.Fragment>
                    <ReactMarkdown
                        source={splitContent[0]}
                        escapeHtml={false}
                        className={styles.ArticleText}
                    />
                    <CreditHealthCheckQuizIframe />
                    <ReactMarkdown
                        source={splitContent[1]}
                        escapeHtml={false}
                        className={styles.ArticleText}
                    />
                </React.Fragment>
            );
        }

        if (blogContent.includes('{{stockupsmall}}')) {
            return <StockUpSmall />;
        }

        if (blogContent.includes('$')) {
            return (
                <MarkdownRender
                    className={styles.ArticleText}
                    source={blogContent}
                    escapeHtml={false}
                />
            );
        }

        return (
            <ReactMarkdownWithHtml
                source={blogContent}
                escapeHtml={false}
                renderers={defaultRenderers}
                className={styles.ArticleText}
            />
        );
    }

    return <>{renderContent()}</>;
};

export default ContentfulBlogContentBlock;
