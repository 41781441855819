import React from 'react';
import BBBImage from './SideBBBimage.png';
import BBBImageMobile from './BBBImageMobile.png';
import styles from './CTABoxV3.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import TickInCircleSVG from 'aphrodite-shared/icons/TickInCircleSVG';
import classnames from 'classnames';
import IwLink from 'aphrodite-shared/ui/IwLink/IwLink';
import InfoIcon from 'aphrodite-shared/SVGComponents/InfoIcon';

const CTABoxV3 = ({ content, className }) => {
    return (
        <div
            className={classnames(className, styles.CTABoxV2)}
            key={content.key}>
            <div className={styles.ImageContainer}>
                <img src={BBBImage} className={styles.Image} />
                <img src={BBBImageMobile} className={styles.ImageMobile} />
            </div>
            <div className={styles.Wrapper}>
                <p className={`${styles.h2} ${styles.DesktopHeader}`}>
                    With an iwoca CBILS loan you can
                </p>
                <p className={`${styles.h2} ${styles.MobileHeader}`}>
                    iwoca CBILS loan
                </p>
                <ul>
                    <li>
                        <TickInCircleSVG
                            className={styles.TickInCircle}
                            fill="#009967"
                        />
                        <p className={styles.BulletPoint}>
                            Borrow £50,001 to £750,000 up to 5 years
                        </p>
                    </li>
                    <li>
                        <TickInCircleSVG
                            className={styles.TickInCircle}
                            fill="#009967"
                        />
                        <p className={styles.BulletPoint}>
                            Pay nothing for the first 12 months
                        </p>
                    </li>
                    <li>
                        <TickInCircleSVG
                            className={styles.TickInCircle}
                            fill="#009967"
                        />
                        <p className={styles.BulletPoint}>
                            Have your own account manager on hand
                        </p>
                    </li>
                </ul>
                <div className={styles.CTAs}>
                    <a
                        className={styles.Link}
                        data-ga-id="article_cta_findoutmore__CBILS_lp"
                        href="/cbils/">
                        Find out more
                    </a>
                    <Button
                        colour="primary"
                        href="/cbils-eligibility/"
                        dataGaId="article_cta__apply">
                        Apply now
                    </Button>
                </div>
                <div className={styles.flexiLink}>
                    <div className={styles.svg}>
                        <InfoIcon />
                    </div>
                    <p>
                        If you haven't been impacted by COVID-19{' '}
                        <IwLink to="/flexi-loan/">
                            check out our Flexi-Loan
                        </IwLink>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CTABoxV3;
