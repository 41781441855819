import React from 'react';

const TimeIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="8.5" stroke="white" />
            <line
                x1="9.41891"
                y1="2.28369"
                x2="9.41891"
                y2="8.41883"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="9.41891"
                y1="8.41895"
                x2="15.2297"
                y2="8.41895"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TimeIcon;
