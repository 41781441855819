import { defineMessages } from 'react-intl';

export default defineMessages({
    authorName: {
        id: '_.author__words_by',
        defaultMessage: 'words by <span>{authorName}</span>'
    },
    shareArticle: {
        id: '_.social_media__share_article',
        defaultMessage: 'Share articles'
    },
    recentArticles: {
        id: '_.recent_articles__recent_stories',
        defaultMessage: 'Recent stories'
    },
    readTime: {
        id: '_.above_the_fold__read_time',
        defaultMessage: `{value} min read`
    },
    articleUpdated: {
        id: '_.author__article_updated',
        defaultMessage: `Article updated on:`
    },
    youMightEnjoy: {
        id: '_.more_articles__you_might_enjoy',
        defaultMessage: 'Related articles'
    }
});
