import React, { useState, useRef } from 'react';
import {
    playYoutubeVideo,
    pauseYoutubeVideo
} from '../../../../../util/youtubeInteraction';
import styles from './MediaAndCaption.module.scss';

const MediaAndCaption = ({ data }) => {
    const [isPlaying, setPlaying] = useState(false);
    const videoRef = useRef(null);
    const { caption, image, videoPlayButton, youtubeVideoId } = data;

    const handleVideoClick = () => {
        const refCurrent = videoRef.current;

        if (!isPlaying) {
            playYoutubeVideo(refCurrent);
        } else {
            pauseYoutubeVideo(refCurrent);
        }

        setPlaying(!isPlaying);
    };

    return (
        <div className={styles.mediaCaption}>
            <div
                className={styles.media}
                style={
                    image ? { backgroundImage: `url("${image.file.url}")` } : {}
                }>
                {youtubeVideoId && youtubeVideoId !== '' && videoPlayButton && (
                    <div>
                        <button
                            className={styles.button}
                            style={{
                                backgroundImage: `url("${videoPlayButton.file.url}")`
                            }}
                            onClick={handleVideoClick}
                            onTouchStart={handleVideoClick}
                        />
                        <iframe
                            className={`${styles.video} ${
                                isPlaying ? styles.play : ''
                            }`}
                            title="embeded video"
                            ref={videoRef}
                            width="100%"
                            height="100%"
                            src={`https://www.youtube-nocookie.com/embed/${youtubeVideoId}?wmode=transparent&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&enablejsapi=1`}
                            frameBorder="0"
                            allowFullScreen="1"
                        />
                    </div>
                )}
            </div>
            <small className={styles.caption}>{caption}</small>
        </div>
    );
};

export default MediaAndCaption;
