import React from 'react';
import classnames from 'classnames';

import styles from './Social.module.scss';
import SocialMediaBar from '../Navigator/SocialMediaBar/SocialMediaBar';

import DynamicTrustpilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';

const Social = ({ fullSlug, isTransactionalPage }) => {
    return (
        <div
            className={classnames(styles.Social, {
                [styles.transactionalPage]: isTransactionalPage
            })}>
            <SocialMediaBar
                fullSlug={fullSlug}
                isTransactionalPage={isTransactionalPage}
            />
            <DynamicTrustpilot />
        </div>
    );
};

export default Social;
