import React from 'react';
import LeftQuote from '../svg/LeftQuote';
import RightQuote from '../svg/RightQuote';
import styles from './ShortBlockQuote.module.scss';

const ShortBlockQuote = ({ content }) => {
    return (
        <div className={styles.ShortBlockQuote}>
            <LeftQuote className={styles.LeftQuote} />
            <p className={styles.Content}>"{content}"</p>
            <RightQuote className={styles.RightQuote} />
        </div>
    );
};

export default ShortBlockQuote;
