import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './LongInlineQuote.module.scss';

const LongInlineQuote = ({ content }) => {
    return (
        <>
            <ReactMarkdown
                className={styles.LongInlineQuote}
                source={content}
            />
        </>
    );
};

export default LongInlineQuote;
