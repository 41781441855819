import React, { useContext } from 'react';
import { ArticlePageContext } from '../../ArticlePageContext';
import Button from 'aphrodite-shared/ui/Button/Button';
import styles from './OpinionWidget.module.scss';
import classnames from 'classnames';

const OpinionWidget = ({
    className,
    buttonContainerClass,
    successText,
    displayEmoji = true
}) => {
    const articleContext = useContext(ArticlePageContext);

    const buttonsState = articleContext.buttonsState;
    const handleClick = articleContext.handleClick;
    const isSubmitted = articleContext.isSubmitted;

    const yesButton = buttonsState['yes'] ? styles.buttonPressed : '';
    const noButton = buttonsState['no'] ? styles.buttonPressed : '';
    const yesButtonNoAnimation = getActivityClass(
        isSubmitted,
        buttonsState['yes']
    );
    const noButtonNoAnimation = getActivityClass(
        isSubmitted,
        buttonsState['no']
    );

    return (
        <div className={classnames(className, styles.OpinionWidget)}>
            <h3>{isSubmitted ? successText : 'Is this article helpful?'}</h3>
            <div
                className={classnames(
                    buttonContainerClass,
                    styles.OpinionButtonContainer
                )}>
                <Button
                    dataGaId="thumbsup"
                    onClick={() => handleClick('yes')}
                    buttonClass={classnames(
                        yesButtonNoAnimation,
                        styles.OpinionButton,
                        yesButton
                    )}>
                    {displayEmoji && '👍'} Yes
                </Button>
                <Button
                    dataGaId="thumbsdown"
                    onClick={() => handleClick('no')}
                    buttonClass={classnames(
                        noButtonNoAnimation,
                        styles.OpinionButton,
                        noButton
                    )}>
                    {displayEmoji && '👎'} No
                </Button>
            </div>
        </div>
    );
};

const getActivityClass = (isSubmitted, isButtonClicked) => {
    if (isSubmitted && isButtonClicked) {
        return styles.noAnimationKeepBg;
    } else if (isSubmitted && !isButtonClicked) {
        return styles.noAnimation;
    } else {
        return '';
    }
};

export default OpinionWidget;
