import React, { useState } from 'react';

export const ArticlePageContext = React.createContext({});
export const ArticlePageContextConsumer = ArticlePageContext.Consumer;

export function ArticlePageContextProvider({ children }) {
    const [buttonsState, setIsClicked] = useState({ no: false, yes: false });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleClick = (buttonVersion) => {
        if (!isSubmitted) {
            setIsClicked({
                ...buttonsState,
                [buttonVersion]: !buttonsState[buttonVersion]
            });
            setIsSubmitted(true);
        }
    };
    return (
        <ArticlePageContext.Provider
            value={{ handleClick, isSubmitted, buttonsState }}>
            {children}
        </ArticlePageContext.Provider>
    );
}
