import React, { useState } from 'react';
import CopyIcon from './CopyIcon';
import classnames from 'classnames';
import styles from './CopyMediaIcon.module.scss';

const CopyMediaIcon = () => {
    const [isActive, setActive] = useState(false);
    const handleClick = () => {
        copyLink();
        setActive(true);
        setTimeout(() => {
            setActive(false);
        }, 1000);
    };

    const copyLink = () => {
        var url = document.createElement('input'),
            text = window.location.href;

        document.body.appendChild(url);
        url.value = text;
        url.select();
        document.execCommand('copy');
        document.body.removeChild(url);
    };

    return (
        <>
            <div className={styles.MediaIcon} onClick={handleClick}>
                <CopyIcon className={styles.Icon} />
            </div>
            <CopyToast isActive={isActive} />
        </>
    );
};

const CopyToast = ({ isActive }) => {
    const activeClass = isActive ? styles.isActive : '';

    return (
        <div className={classnames(styles.CopyToast, activeClass)}>
            Link copied!
        </div>
    );
};

export default CopyMediaIcon;
