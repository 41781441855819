import React from 'react';
import en from 'date-fns/locale/en';
import de from 'date-fns/locale/de';
import format from 'date-fns/format';
import messages from '../../Article.messages';
import styles from './Author.module.scss';
import { useIntl } from 'react-intl';
import GatsbyImage from 'gatsby-image';

const locales = {
    de,
    en
};

const Author = ({ author, date }) => {
    const { authorImage, authorName, authorBio } = author;

    const { formatMessage } = useIntl();

    function formattedDate() {
        return format(new Date(date), 'D MMMM YYYY', {
            locale: locales[process.env.GATSBY_COUNTRY_BUILD]
        });
    }

    return (
        <div className={styles.Author}>
            <div className={styles.AuthorIconContainer}>
                <div className={styles.Pane}>
                    <GatsbyImage
                        fluid={authorImage.fluid}
                        className={styles.authorImage}
                    />
                    <p>
                        {formatMessage(
                            {
                                ...messages.authorName
                            },
                            {
                                span: (msg) => (
                                    <span className={styles.authorName}>
                                        {msg}
                                    </span>
                                ),
                                authorName: authorName
                            }
                        )}
                    </p>
                </div>
            </div>
            <div className={styles.AuthorBio}>
                {authorBio && <p>{authorBio.authorBio}</p>}
            </div>
            {date && (
                <div className={styles.PublishDate}>
                    Article updated on:{' '}
                    <span className={styles.Date}>{formattedDate()}</span>
                </div>
            )}
        </div>
    );
};

export default Author;
