import React from 'react';

import styles from './ApplyCTA.module.scss';
import DynamicTrustpilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import Button from 'aphrodite-shared/ui/Button/Button';
import Bank from './SVG/Bank';
import NoFees from './SVG/NoFees';
import Calendar from './SVG/Calendar';
import InfoIcon from 'aphrodite-shared/SVGComponents/InfoIcon';
import IwLink from 'aphrodite-shared/ui/IwLink/IwLink';

const ApplyCTA = () => (
    <section className={styles.ApplyCTA}>
        <div className={styles.info}>
            <h2>Apply for an unsecured business loan</h2>
            <p className={styles.p}>
                If you’d like to see your business thrive but don’t want to
                restrict your day-to-day operations, an unsecured business loan
                could be for you.
            </p>
            <div className={styles.mobileCTAs}>
                <div className={styles.covidContainer}>
                    <div className={styles.covid}>
                        <div className={styles.icon}>
                            <InfoIcon background="#fff" icon="#1B2D37" />
                        </div>
                        <p>
                            If you haven't been impacted by COVID-19{' '}
                            <IwLink to="/flexi-loan/">
                                check out our Flexi-Loan
                            </IwLink>
                        </p>
                    </div>
                </div>
                <Button
                    colour="primary"
                    buttonClass={styles.cta}
                    to="/cbils-eligibility/">
                    Apply for a CBILS loan
                </Button>
            </div>
            <DynamicTrustpilot className={styles.trustpilot} />
        </div>
        <div className={styles.uspContainer}>
            <div className={styles.usps}>
                <div className={styles.usp}>
                    <div className={styles.svg}>
                        <Bank />
                    </div>
                    <p>Borrow £1,000 to £500,000 over 24 months</p>
                </div>
                <div className={styles.usp}>
                    <div className={styles.svg}>
                        <NoFees />
                    </div>
                    <p>Get a decision in 1 working day</p>
                </div>
                <div className={styles.usp}>
                    <div className={styles.svg}>
                        <Calendar />
                    </div>
                    <p>Repay early with no fees</p>
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    colour="primary"
                    buttonClass={styles.cta}
                    to="/flexi-loan/">
                    Apply for a business loan
                </Button>
            </div>
        </div>
    </section>
);

export default ApplyCTA;
