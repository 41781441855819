import React from 'react';
import BookSVG from './BookSVG';
import IwocaLogoSVG from '../../../SVGComponents/IwocaLogoSVG';
import styles from './Book.module.scss';

const Book = ({ heading, subHeading }) => {
    return (
        <div className={styles.Book}>
            <div className={styles.BookOverlay}>
                <p className={styles.heading}>{heading}</p>
                <div className={styles.divider}></div>
                <p className={styles.subHeading}>{subHeading}</p>
                <div className={styles.bookLicensing}>
                    <span className={styles.Author}>BY DANIEL SMITH</span>
                    <IwocaLogoSVG className={styles.IwocaLogo} />
                </div>
            </div>
            <BookSVG />
        </div>
    );
};

export default Book;
