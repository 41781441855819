import React from 'react';
import GatsbyImg from 'gatsby-image';
import styles from './MediaIcon.module.scss';

const MediaIcon = ({ image, url }) => {
    return (
        <div className={styles.MediaIcon}>
            <a href={url}>
                <GatsbyImg fluid={image.fluid} />
            </a>
        </div>
    );
};

export default MediaIcon;
