export function playYoutubeVideo(videoIframe) {
    videoIframe.contentWindow.postMessage(
        getYoutubeVideoApiCommand('playVideo'),
        '*'
    );
}

export function pauseYoutubeVideo(videoIframe) {
    videoIframe.contentWindow.postMessage(
        getYoutubeVideoApiCommand('stopVideo'),
        '*'
    );
}

function getYoutubeVideoApiCommand(command) {
    return JSON.stringify({
        event: 'command',
        func: command,
        args: ''
    });
}
