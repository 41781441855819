import React, { useEffect, useState } from 'react';
import CTABoxStandard from '../CTABoxes/CTABoxStandard/CTABoxStandard';
import CTABoxV3 from '../CTABoxes/CTABoxV3/CTABoxV3';
import FlexiLoanCTABox from '../CTABoxes/FlexiLoanCTABox/FlexiLoanCTABox';
import CTABoxTransactionalPage from '../CTABoxes/CTABoxTransactionalPage/CTABoxTransactionalPage';

const CTABox = ({ content, className, isCovidHub = false }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) {
        return null;
    }

    const renderCTA = (name) => {
        switch (name) {
            case 'Transactional page CTA widget':
                return <CTABoxTransactionalPage />;
            case 'Flexi-Loan CTA widget':
                return (
                    <FlexiLoanCTABox
                        isCovidHub={isCovidHub}
                        content={content}
                        className={className}
                    />
                );
            default:
                break;
        }
        if (isCovidHub) {
            return <CTABoxV3 content={content} />;
        }
        return <CTABoxStandard content={content} className={className} />;
    };

    return renderCTA(content.name);
};

export default CTABox;
