import React from 'react';
import GatsbyImg from 'gatsby-image';
import defaultImg from './defaultImage.png';
import styles from './CTABoxStandard.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';

const CTABoxStandard = ({ content, className }) => {
    let gaId;
    if (content.callToAction.url === '#compare') gaId = 'article_widget__cta';
    if (content.name === 'iwocaPay CTA') gaId = 'article_widget__iwoca_pay';
    if (content.name === 'Stock up small')
        gaId = 'article_widget__stock_up_small';

    const [CTABox, primaryButton, secondaryButton] = getDesign(
        content.designOptions
    );

    return (
        <div
            key={content.key}
            className={classnames(styles.CTABox, CTABox, className)}>
            <div className={styles.LeftPane}>
                <div className={styles.ContentContainer}>
                    {content.title && (
                        <p className={styles.h4}>{content.title}</p>
                    )}
                    <ReactMarkdown
                        className={styles.h5}
                        source={content.copy.copy}
                    />
                    <div className={styles.buttonContainer}>
                        {content.callToAction && (
                            <Button
                                className={classnames(
                                    styles.CTAButton,
                                    primaryButton
                                )}
                                href={content.callToAction.url}
                                title={content.callToAction.buttonText}
                                colour="primary"
                                dataGaId={content.callToAction.dataGaId || gaId}
                                isDownloadable={
                                    content.callToAction.isDownloadable
                                }
                                big>
                                {content.callToAction.buttonText}
                            </Button>
                        )}
                        {content.secondCallToAction && (
                            <Button
                                className={classnames(
                                    styles.CTAButton,
                                    secondaryButton
                                )}
                                href={content.secondCallToAction.url}
                                title={content.secondCallToAction.buttonText}
                                dataGaId={content.callToAction.dataGaId || gaId}
                                isDownloadable={
                                    content.secondCallToAction.isDownloadable
                                }
                                big>
                                {content.secondCallToAction.buttonText}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.RightPane}>
                {content.image ? (
                    <GatsbyImg
                        fluid={content.image.fluid}
                        className={styles.Image}
                    />
                ) : (
                    <img
                        src={defaultImg}
                        className={styles.Image}
                        alt="defaultImage"
                    />
                )}
            </div>
        </div>
    );
};

const getDesign = (designOption) => {
    switch (designOption) {
        case 'Blue':
            return [
                styles.CTABoxBlue,
                styles.CTAButtonWhite,
                styles.SecondCTAButton
            ];
        case 'Green':
            return [
                styles.CTABoxGreen,
                styles.CTAButtonGreen,
                styles.CTAButtonGreen
            ];
        default:
            return ['', '', ''];
    }
};

export default CTABoxStandard;
