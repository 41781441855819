import React from 'react';
import lodashGet from 'lodash/get';
import styles from '../ContentfulBlogContentBlock.module.scss';

export const StockUpSmallCategory = ({ title, data }) => {
    if (data[0]) {
        return (
            <div className={styles.noFollow}>
                <h2>{title}</h2>
                {data.map((item) => {
                    return (
                        <div>
                            <p>
                                <strong>
                                    {lodashGet(item, 'node.website') ? (
                                        <a
                                            href={item.node.website}
                                            rel="nofollow">
                                            {item.node.companyName}
                                        </a>
                                    ) : (
                                        item.node.companyName
                                    )}
                                </strong>
                            </p>
                            <ul>
                                {item.node.whatDoTheySell.whatDoTheySell && (
                                    <li>
                                        <strong>What do they do?</strong>{' '}
                                        {
                                            item.node.whatDoTheySell
                                                .whatDoTheySell
                                        }
                                    </li>
                                )}
                                {item.node.doTheyDeliver && (
                                    <li>
                                        <strong>Do they deliver?</strong>{' '}
                                        {item.node.doTheyDeliver}
                                    </li>
                                )}
                                {item.node.deliveryAreas && (
                                    <li>
                                        <strong>Delivery areas</strong>{' '}
                                        {item.node.deliveryAreas}
                                    </li>
                                )}
                                {item.node.howDoYouOrder && (
                                    <li>
                                        <strong>How do you order?</strong>{' '}
                                        {item.node.howDoYouOrder}
                                    </li>
                                )}
                                {item.node.specialOffers.specialOffers && (
                                    <li>
                                        <strong>Any special offers?</strong>{' '}
                                        {item.node.specialOffers.specialOffers}
                                    </li>
                                )}
                                {item.node.emailAddress && (
                                    <li>
                                        <strong>Business email address</strong>{' '}
                                        {item.node.emailAddress}
                                    </li>
                                )}
                                {item.node.phoneNumbver && (
                                    <li>
                                        <strong>Business email address</strong>{' '}
                                        {item.node.emailAddress}
                                    </li>
                                )}
                            </ul>
                        </div>
                    );
                })}
            </div>
        );
    }

    return null;
};
