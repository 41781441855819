import React from 'react';

const Bank = () => (
    <svg
        width="54"
        height="53"
        viewBox="0 0 54 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.14355 18.9502V43.6676H46.6914V18.9502"
            stroke="#BFD4E0"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M49.9868 15.6544L26.9172 4.11963L3.84766 15.6544V18.9501H49.9868V15.6544Z"
            stroke="#BFD4E0"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.6746 27.9138C22.6747 28.856 22.8818 29.6337 23.1235 30.371H22.0657C21.8985 30.371 21.7375 30.415 21.6168 30.5254C21.4935 30.6382 21.4378 30.7969 21.4378 30.9729V31.2593C21.4378 31.4348 21.4932 31.5948 21.6184 31.7067C21.7405 31.8158 21.9024 31.8553 22.0699 31.8482H23.5604C23.6717 32.3231 23.7552 32.8466 23.7552 33.4205C23.7552 34.7915 22.7261 35.7042 21.7827 36.4379L21.7827 36.4379L21.7807 36.4395C21.669 36.5288 21.5801 36.6268 21.5208 36.7454C21.4611 36.8649 21.4378 36.9924 21.4378 37.131V37.5085C21.4378 37.6844 21.4931 37.8447 21.6095 37.9636C21.7253 38.0819 21.8839 38.141 22.0569 38.1492L22.0569 38.1494H22.0657H31.6868C31.8622 38.1494 32.0237 38.0944 32.1417 37.9764C32.2597 37.8584 32.3147 37.6969 32.3147 37.5215V37.0789C32.3147 36.9036 32.2597 36.7421 32.1417 36.624C32.0237 36.506 31.8622 36.451 31.6868 36.451H24.0183C24.826 35.7756 25.4927 34.7746 25.4927 33.5116C25.4927 32.897 25.4122 32.3633 25.296 31.8612H29.734C29.9012 31.8612 30.0622 31.8173 30.1829 31.7069C30.3061 31.5941 30.3619 31.4354 30.3619 31.2593V30.9729C30.3619 30.7969 30.3061 30.6382 30.1829 30.5254C30.0622 30.415 29.9012 30.371 29.734 30.371H24.8451C24.6143 29.6231 24.4121 28.8856 24.4121 27.9915C24.4121 26.8639 24.7097 26.002 25.2318 25.424C25.7515 24.8485 26.5144 24.5314 27.4947 24.5314C28.8779 24.5314 29.8114 25.2174 30.395 26.1725C30.4777 26.3147 30.5872 26.437 30.732 26.5022C30.8824 26.5698 31.0456 26.5647 31.2074 26.4977L31.633 26.343L31.6398 26.3405L31.6463 26.3375C31.8005 26.2674 31.9478 26.1724 32.0292 26.0326C32.1202 25.8762 32.1077 25.7032 32.0207 25.537C31.2218 23.9672 29.6362 22.846 27.4947 22.846C26.063 22.846 24.8494 23.2917 23.9954 24.161C23.1416 25.0303 22.668 26.302 22.6746 27.9138ZM22.6746 27.9138C22.6746 27.9139 22.6746 27.9141 22.6746 27.9142L22.8599 27.9134H22.6746C22.6746 27.9136 22.6746 27.9137 22.6746 27.9138ZM23.3806 30.5563C23.3597 30.4947 23.339 30.4329 23.3185 30.371L23.3806 30.5563Z"
            fill="#FB534A"
            stroke="#FB534A"
            strokeWidth="0.370516"
        />
        <path
            d="M13.7344 18.9502V43.6676"
            stroke="#BFD4E0"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.0996 18.9502V43.6676"
            stroke="#BFD4E0"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.14331 43.6675L3.84766 45.3153V48.611H49.9868V45.3153L46.6912 43.6675"
            stroke="#BFD4E0"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Bank;
