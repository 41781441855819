import React from 'react';

const CopyIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="14" fill="#2B4959" />
            <rect
                x="8.5"
                y="7.5"
                width="7"
                height="10"
                rx="0.5"
                stroke="white"
            />
            <path
                d="M13 17.5V19.5C13 19.7761 13.2239 20 13.5 20H19.5C19.7761 20 20 19.7761 20 19.5V10.5C20 10.2239 19.7761 10 19.5 10L16 10"
                stroke="white"
            />
        </svg>
    );
};

export default CopyIcon;
