import React from 'react';

const NoFees = () => (
    <svg
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.0859 49.7305C39.6839 49.7305 49.0859 40.3284 49.0859 28.7305C49.0859 17.1325 39.6839 7.73047 28.0859 7.73047C16.488 7.73047 7.08594 17.1325 7.08594 28.7305C7.08594 40.3284 16.488 49.7305 28.0859 49.7305Z"
            stroke="#BFD4E0"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.6701 38.801L21.6582 38.8004C21.3768 38.787 21.0993 38.6894 20.8907 38.4764C20.6806 38.2618 20.5889 37.9794 20.5889 37.6905V37.183C20.5889 36.9679 20.6252 36.7564 20.7268 36.5534C20.8272 36.3525 20.9743 36.1943 21.1433 36.0591L21.1486 36.0548C22.4247 35.0623 23.7039 33.9038 23.7039 32.1955C23.7039 31.5241 23.6162 30.9039 23.492 30.331H21.6925C21.4254 30.3413 21.1395 30.2783 20.9147 30.0775C20.681 29.8686 20.5889 29.5783 20.5889 29.2905V28.9055C20.5889 28.6159 20.6818 28.3293 20.9104 28.1202C21.1321 27.9174 21.4161 27.8474 21.6819 27.8474H22.7627C22.4804 26.939 22.2515 25.9578 22.2514 24.794C22.2424 22.5783 22.8943 20.8002 24.0982 19.5746C25.3024 18.3486 27.0031 17.7324 28.9794 17.7324C31.954 17.7324 34.1692 19.2942 35.2844 21.4844C35.431 21.7651 35.4644 22.09 35.2899 22.39C35.1411 22.6457 34.8851 22.8004 34.663 22.9014L34.6453 22.9094L34.0599 23.1223C33.7909 23.2322 33.5013 23.2457 33.2288 23.1231C32.971 23.0071 32.7903 22.7968 32.6641 22.5805C31.9177 21.3603 30.7381 20.496 28.9794 20.496C27.7178 20.496 26.765 20.9022 26.1225 21.6136C25.4737 22.3319 25.0849 23.424 25.0849 24.898C25.0849 25.9928 25.3172 26.9087 25.6021 27.8474H31.9894C32.2553 27.8474 32.5392 27.9174 32.7609 28.1202C32.9895 28.3293 33.0824 28.6159 33.0824 28.9055V29.2905C33.0824 29.58 32.9895 29.8666 32.7609 30.0757C32.5392 30.2786 32.2553 30.3485 31.9894 30.3485H26.3332C26.4562 30.9496 26.5374 31.5927 26.5374 32.318C26.5374 33.8492 25.8469 35.1006 24.9434 36.0199H34.6144C34.902 36.0199 35.1874 36.111 35.4019 36.3255C35.6163 36.5399 35.7074 36.8254 35.7074 37.113V37.708C35.7074 37.9955 35.6163 38.281 35.4019 38.4954C35.1874 38.7099 34.902 38.801 34.6144 38.801H21.6701Z"
            fill="#FB534A"
        />
        <path
            d="M46.4619 19.1055L9.71191 37.4805"
            stroke="#BFD4E0"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default NoFees;
