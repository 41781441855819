import React from 'react';

import styles from './CTABoxTransactionalPage.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import IwLink from 'aphrodite-shared/ui/IwLink/IwLink';
import InfoIcon from 'aphrodite-shared/SVGComponents/InfoIcon';

const CTABoxTransactionalPage = () => (
    <div className={styles.CTABoxTransactionalPage}>
        <Button colour="primary" to="/flexi-loan/">
            Apply for a business loan
        </Button>
    </div>
);

export default CTABoxTransactionalPage;
